import { useModalContext } from 'src/design-system'
import * as React from 'react'
import { Invitee } from './invite-modal'

export const INVITE_MODAL_ID = 'invite-modal'

export const emptyInvitee: Invitee = {
  id: 0,
  email: '',
  fname: '',
  lname: '',
  positionId: '',
  admin: false,
  error: null,
}

export const useOpenInviteModal = () => {
  const { openModal } = useModalContext()

  const openInviteModal = React.useCallback(
    ({ positionId, intent }: { positionId?: string; intent: string }) => {
      openModal(INVITE_MODAL_ID, {
        positionId,
        intent,
      })
    },
    [openModal]
  )

  return {
    openInviteModal,
  }
}
